<template lang="pug">
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab(:ripple='false') {{ $t("common.searchDate") }}
		v-tabs-items(v-model='tab')
			v-tab-item
				v-card.pa-4.pb-0.filter-wrap
					v-row
						v-col.px-3.py-0(cols='12', sm='8', md='6', lg='3')
							DatePicker(@emitupdateTodayDate='updateTodayDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			MainSelectItem(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row.justify-center(v-if='basic.items.length !== 0')
					v-col.px-0(cols='12')
						v-simple-table.dataTableWrap
							template(v-slot:default)
								tbody
									tr(v-for='item in basic.items', :key='item.name')
										td {{ item.name }}
										td.red--text.text-right(v-if='item.negative') {{ item.value }}
										td.text-right(v-else) {{ item.value }}
				v-row.justify-center
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.apv_bet).length !== 0')
						v-chart(:option='line_chart_options.apv_bet', ref='line_chart_options.apv_bet', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.house_net_win).length !== 0')
						v-chart(:option='line_chart_options.house_net_win', ref='line_chart_options.house_net_win', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.gm_users).length !== 0')
						v-chart(:option='line_chart_options.gm_users', ref='line_chart_options.gm_users', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.reg_users).length !== 0')
						v-chart(:option='line_chart_options.reg_users', ref='line_chart_options.reg_users', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.dpst_amt).length !== 0')
						v-chart(:option='line_chart_options.dpst_amt', ref='line_chart_options.dpst_amt', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.revenue_amt).length !== 0')
						v-chart(:option='line_chart_options.revenue_amt', ref='line_chart_options.revenue_amt', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(line_chart_options.dpst_users).length !== 0')
						v-chart(:option='line_chart_options.dpst_users', ref='line_chart_options.dpst_users', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(ccuLine).length !== 0')
						v-chart(:option='ccuLine', ref='ccuLine', autoresize)
					v-col.chartWrap(cols='12', lg='6', v-if='Object.keys(revenueBar).length !== 0')
						v-chart(:option='revenueBar', ref='revenueBar', autoresize)
					v-col.chartWrap.dataSelectChart(cols='12', lg='6', v-if='Object.keys(domainBar).length !== 0')
						v-col.dataSelectBox
							DataPicker(:dataStatus='data', @emitupdateData='updateData')
						v-chart.chartMoveUp_more(:option='domainBar', ref='domainBar', autoresize)
				v-row.justify-center
					v-col.chartWrap.dataSelectChart(cols='12', lg='6', v-if='Object.keys(brandBar).length !== 0')
						v-col.dataSelectBox
							DataPicker(:dataStatus='data', @emitupdateData='updateData')
						v-chart.chartMoveUp_more(:option='brandBar', ref='brandBar', autoresize)
					v-col.chartWrap.dataSelectChart(cols='12', lg='6', v-if='Object.keys(gameTypeBar).length !== 0')
						v-col.dataSelectBox
							DataPicker(:dataStatus='data', @emitupdateData='updateData')
						v-chart.chartMoveUp_more(:option='gameTypeBar', ref='gameTypeBar', autoresize)
				v-row.justify-center
					v-col.chartWrap(cols='12', lg='12', v-if='Object.keys(devicePie).length !== 0')
						v-chart(:option='devicePie', ref='devicePie', autoresize)
					v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByDomain).length !== 0')
						span.title {{ $t("charts.netWinPerDomain") }}
						v-data-table.elevation-1.dataTableWrap(
							:items-per-page='itemPerPage',
							:headers='domainHeaders',
							:items='netWinByDomain',
							hide-default-footer,
							style='width: 100%',
							:expanded.sync='domainExpanded',
							item-key='dim_site_id',
							show-expand,
							:mobile-breakpoint='0'
						)
							template(v-slot:item.house_net_win='{ item }')
								span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
								span(v-else) {{ item.house_net_win | commaFormatter }}
							template(v-slot:expanded-item='{ item }')
								td.pa-2.expandedTd(:colspan='3')
									v-data-table.pa-2.expandedTable(
										:items-per-page='itemPerPage',
										:headers='domainSubHeaders',
										:items='item.items',
										hide-default-footer,
										style='width: 100%',
										:mobile-breakpoint='0',
										dense
									)
										template(v-slot:item.house_net_win='{ item }')
											span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
											span(v-else) {{ item.house_net_win | commaFormatter }}
					v-col.px-1(cols='12', lg='6', v-if='Object.keys(netWinByBrand).length !== 0')
						span.title {{ $t("charts.netWinPerBrand") }}
						v-data-table.elevation-1.dataTableWrap(
							:items-per-page='itemPerPage',
							:headers='brandHeaders',
							:items='netWinByBrand',
							hide-default-footer,
							style='width: 100%',
							:expanded.sync='brandExpanded',
							item-key='dim_brand_code',
							show-expand,
							:mobile-breakpoint='0',
							:class='netWinByBrand.negative ? "b" : "a"'
						)
							template(v-slot:item.house_net_win='{ item }')
								span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
								span(v-else) {{ item.house_net_win | commaFormatter }}
							template(v-slot:expanded-item='{ item }')
								td.pa-2.expandedTd(:colspan='3')
									v-data-table.pa-2.expandedTable(
										:items-per-page='itemPerPage',
										:headers='brandSubHeaders',
										:items='item.items',
										hide-default-footer,
										style='width: 100%',
										:mobile-breakpoint='0',
										dense
									)
										template(v-slot:item.house_net_win='{ item }')
											span.turnRed(v-if='item.negative') {{ item.house_net_win | commaFormatter }}
											span(v-else) {{ item.house_net_win | commaFormatter }}
</template>

<script>
import _ from 'lodash';
import ECharts from 'vue-echarts';
import statusCode from '@/assets/constant/statusCode';
import { mapActions, mapGetters } from 'vuex';
import chart_option_helper from '@/assets/echarts/chart_option_helper';
import { revenueChart } from '@/assets/echarts/ocmsCommon/revenue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { getToday } from '@/api/DataMonitor';
import { bar, line, pie } from '@/assets/echarts/basicCommon/dataMointor';
import { pie as norPie } from '@/assets/echarts/ocmsCommon/customer/single';
import { nFormatter, roundOff, rateOfExchange, roundDecimal, rdPercent, roundOffFilter, trans, transData, toThousandslsFilter } from '@/util/format';
import DatePicker from '@/components/DatePicker/DatePicker_ocms';
import DataPicker from '@/components/DataPicker/DataPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem from '@/components/MainSelectItemOCMS';
import { exportXlsx } from '@/util/xlsx';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		ValidationObserver,
		ValidationProvider,
		DatePicker,
		DataPicker,
		ExportBtn,
		MainSelectItem,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			helperShow: false,
			getTodayResult: {},
			getExportResult: {},
			currencyStatus: true,
			date: this.$moment().format('YYYY-MM-DD'),
			data: this.$t('charts.turnover'),
			searchDates: [],
			onOK: false,
			show: false,
			loading: false,
			basic: {
				headers: [
					{ text: '', align: 'left', sortable: false, value: 'name' },
					{ text: '', sortable: false, value: 'value' },
				],
				items: [],
			},
			countColor: [COLORS.amethyst[5], COLORS.orange[5], COLORS.hotpink, COLORS.nephritis[5], COLORS.peter_river[5], COLORS.dark_gray_indigo],
			deopsit: 0,
			withdraw: 0,
			value: [],
			domainMap: {},
			currency: '',
			domainROE: {},
			brandROE: {},
			gameKindROE: {},

			line_chart_options: {
				apv_bet: {},
				house_net_win: {},
				gm_users: {},
				reg_users: {},
				dpst_amt: {},
				revenue_amt: {},
				dpst_users: {},
			},

			ccuLine: {},
			revenueBar: {},
			domainBar: {},
			brandBar: {},
			gameTypeBar: {},
			devicePie: {},
			netWinByDomain: [],
			netWinByBrand: [],
			itemPerPage: 100,
			domainExpanded: [],
			brandExpanded: [],
			domainHeaders: [
				{
					text: this.$t('charts.domain'),
					align: 'left',
					value: 'dim_site_id',
				},
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
				{ text: '', value: 'data-table-expand' },
			],
			domainSubHeaders: [
				{ text: this.$t('charts.brand'), value: 'dim_brand_code' },
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
			],
			brandHeaders: [
				{
					text: this.$t('charts.brand'),
					align: 'left',
					value: 'dim_brand_code',
				},
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
				{ text: '', value: 'data-table-expand' },
			],
			brandSubHeaders: [
				{ text: this.$t('charts.gameKind'), value: 'game_kind_name' },
				{ text: this.$t('common.sort'), align: 'right', value: 'house_net_win' },
			],
			tab: null,
			modal: false,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		smoothDown() {
			$('html, body').animate(
				{
					scrollTop: $('.searchBtn').offset().top,
				},
				500
			);
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						let tmpJ = this.domainMap[i].split('-');
						domain.push(tmpJ[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}
				const reqData = {
					startDate: this.$moment(this.date).subtract(1, 'day').format('YYYY-MM-DD'),
					endDate: this.$moment(this.date).format('YYYY-MM-DD'),
					domain,
					currency: this.currency
				};
				this.searchDates = [reqData.endDate];
				const res = await getToday(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = res.result.res;
					this.getTodayResult = JSON.parse(JSON.stringify(result));
					this.getExportResult = JSON.parse(JSON.stringify(result));
					this.termly = JSON.parse(JSON.stringify(result.ctx_termly));
					this.gaming = JSON.parse(JSON.stringify(result.ctx_gaming));
					this.loginSum = result.ctx_login;
					this.recap = JSON.parse(JSON.stringify(result.ctx_recap));
					this.minutely = JSON.parse(JSON.stringify(result.ctx_ccu_minutely));

					this.hauChart(this.termly);
					this.ccuChart(this.minutely);
					this.revenueChart(await trans(this.termly, 'dim_ptt', 'dpst_amt'), await trans(this.termly, 'dim_ptt', 'wdrl_amt'));
					// Domain Chart
					this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
					this.domainChart(await trans(this.gaming, 'dim_site_id', 'apv_bet'), await trans(this.gaming, 'dim_site_id', 'house_net_win'));
					// brand
					this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
					this.brandChart(await trans(this.gaming, 'dim_brand_code', 'apv_bet'), await trans(this.gaming, 'dim_brand_code', 'house_net_win'));
					// GameType
					this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
					this.gameTypeChart(
						await trans(this.gaming, 'game_kind_name', 'apv_bet'),
						await trans(this.gaming, 'game_kind_name', 'house_net_win')
					);
					// Device
					this.gaming.sort((a, b) => (a.dim_device_id > b.dim_device_id ? 1 : -1));
					this.deviceChart(this.loginSum);
					this.dataTable(this.recap[0]);
					this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
					this.netWinByDomain = await transData(this.gaming, 'dim_site_id', 'dim_brand_code', 'house_net_win');
					this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
					this.netWinByBrand = await transData(this.gaming, 'dim_brand_code', 'game_kind_name', 'house_net_win');
					this.smoothDown();
					this.tab = 0;
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			} catch (e) {
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async dataTable(res) {
			this.basic.items = [
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.apv_bet'), value: roundOffFilter(res.apv_bet)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.house_net_win'), value: roundOffFilter(res.house_net_win)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.dpst_amt'), value: roundOffFilter(res.dpst_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.wdrl_amt'), value: roundOffFilter(res.wdrl_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.revenue_amt'), value: roundOffFilter(res.revenue_amt)},
				{name: this.$t('modifier.prefix_ttl') + this.$t('metric.bonus_amt'), value: roundOffFilter(res.bonus_amt)},
				// {name: this.$t('modifier.prefix_ttl') + this.$t('metric.bonus_20_amt'), value: roundOffFilter(res.bonus_20_amt)},
				// {name: this.$t('modifier.prefix_ttl') + this.$t('metric.profit_amt'), value: roundOffFilter(res.profit_amt)},

				{name: this.$t('metric.house_edge'), value: rdPercent(res.house_edge)},
				{name: this.$t('metric.bonus_rate'), value: rdPercent(res.bonus_rate)},
			];
			this.basic.items.forEach(e_1 => {
				if(parseFloat(e_1.value) < 0)
					e_1.negative = true;
			});
		},
		async hauChart(res) {
			if(!res)
				return;

			res.forEach(e_1 => e_1.dim_hour = this.$moment(e_1.dim_ptt).format('HH'));

			const data_prev_day = res.filter(e_1 => 0 == e_1.is_today);
			const data_curr_day = res.filter(e_1 => 1 == e_1.is_today);

			const chart_data_list = [
				{title: this.$t('headline.hourly') + this.$t('metric.apv_bet'), col_list: [
					{col_data: data_prev_day, col_name: 'apv_bet', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'apv_bet', col_show: this.$t('modifier.day_curr'), color: '#FDD835'},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.house_net_win'), col_list: [
					{col_data: data_prev_day, col_name: 'house_net_win', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'house_net_win', col_show: this.$t('modifier.day_curr'), color: '#26A69A'},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.gm_users_std'), col_list: [
					{col_data: data_prev_day, col_name: 'gm_users', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'gm_users', col_show: this.$t('modifier.day_curr'), color: '#8BC34A'},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.reg_users'), col_list: [
					{col_data: data_prev_day, col_name: 'reg_users', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'reg_users', col_show: this.$t('modifier.day_curr'), color: COLORS.dark_water_blue_green},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.dpst_amt'), col_list: [
					{col_data: data_prev_day, col_name: 'dpst_amt', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'dpst_amt', col_show: this.$t('modifier.day_curr'), color: '#388E3C'},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.revenue_amt'), col_list: [
					{col_data: data_prev_day, col_name: 'revenue_amt', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'revenue_amt', col_show: this.$t('modifier.day_curr'), color: '#F9A825'},
				]},
				{title: this.$t('headline.hourly') + this.$t('metric.dpst_users_std'), col_list: [
					{col_data: data_prev_day, col_name: 'dpst_users', col_show: this.$t('modifier.day_prev'), color: '#90A4AE'},
					{col_data: data_curr_day, col_name: 'dpst_users', col_show: this.$t('modifier.day_curr'), color: COLORS.wisteria[4]},
				]},
			];

			let ix_1 = 0;
			for(const key_1 in this.line_chart_options){
				const col_list = chart_data_list[ix_1].col_list;
				this.line_chart_options[key_1] = chart_option_helper.gen_line_opt({
					title: {text: chart_data_list[ix_1].title},
					legend: {data: col_list.map(e_2 => e_2.col_show)},
					xAxis: {
						data: [...Array(24).keys()].map(e_1 => e_1.toString().padStart(2, '0')),
					},
					dataset: col_list.map(e_2 => {
						return {
							dimensions: ['dim_hour', e_2.col_name],
							source: e_2.col_data,
						};
					}),
					series: col_list.map((e_2, ix_2) => {
						return {
							type: 'line',
							name: e_2.col_show,
							color: e_2.color,
							tooltip: {valueFormatter: value => roundOffFilter(value)},
							datasetIndex: ix_2,
							...(0 == ix_2 ? {areaStyle: {}, symbol: 'none'} : {})
						};
					}),
				});

				ix_1++;
			}
		},
		async ccuChart(res) {
			const tmpToday = [];
			const tmpYesterday = [];
			const today = [];
			const yesterday = [];
			const xAxisData = [];
			const todayXAxis = [];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i of res) {
					if (this.$moment(i.dim_ptt).format('YYYY-MM-DD') === this.$moment(this.date).format('YYYY-MM-DD')) {
						todayXAxis.push(this.$moment(i.dim_ptt).format('HH:mm:ss'));
						tmpToday.push(i);
					} else {
						xAxisData.push(i.dim_ptt);
						tmpYesterday.push(i);
					}
				}
				if (xAxisData.length !== 1440) {
					let ogTime = this.$moment(xAxisData[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss');
					for (let i = 0; i < 1440; i += 1) {
						ogTime = this.$moment(ogTime).add(1, 'm').format('YYYY-MM-DD HH:mm:ss');
						if (xAxisData.indexOf(ogTime) === -1) {
							xAxisData.push(ogTime);
							tmpYesterday.push({
								dim_ptt: ogTime,
								curr_users: null,
							});
						}
					}
				}
				xAxisData.sort();
				//檢查用
				for (let i = 0; i < xAxisData.length; i += 1) {
					const nt = this.$moment(xAxisData[i]).format('HH:mm:ss');
					if (todayXAxis.indexOf(nt) === -1) {
						tmpToday.push({
							dim_ptt: this.$moment(xAxisData[i]).add(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
							curr_users: null,
						});
					}
				}
				tmpYesterday.sort(function (a, b) {
					return a.dim_ptt > b.dim_ptt ? 1 : -1;
				});
				tmpToday.sort(function (a, b) {
					return a.dim_ptt > b.dim_ptt ? 1 : -1;
				});
				for (let i of tmpYesterday) {
					yesterday.push(i.curr_users);
				}
				for (let i of tmpToday) {
					today.push(i.curr_users);
				}
			}
			this.ccuLine = await line();
			this.ccuLine.title.text = this.$t('charts.ccu');
			this.ccuLine.grid = { left: '15%' };
			this.ccuLine.xAxis.boundaryGap = false;
			this.ccuLine.xAxis.axisLabel.formatter = (value) => {
				return `${this.$moment(value).hour()}`;
			};
			this.ccuLine.xAxis.axisLabel.interval = 120;
			this.ccuLine.xAxis.data = xAxisData;
			this.ccuLine.yAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 0)}`;
			};
			this.ccuLine.legend = {
				top: 'bottom',
				textStyle: { color: '#FFF' },
				data: [this.$t('charts.ccuToday'), this.$t('charts.ccuYesterday')],
			};
			this.ccuLine.tooltip.formatter = (params) => {
				const name = `${this.$t('charts.time')}: ${this.$moment(params[0].name).format('HH:mm')}<br/>`;
				let yes =
					typeof params[0].data === 'number'
						? name + `${params[0].marker}${params[0].seriesName}: ${toThousandslsFilter(params[0].data)}`
						: name;
				if (params[1]) {
					if (params[1].data) {
						if (typeof params[0].data === 'number') {
							return (
								name +
								`${params[1].marker}${params[1].seriesName}: ${toThousandslsFilter(params[1].data)}<br/>
							${params[0].marker}${params[0].seriesName}: ${toThousandslsFilter(params[0].data)}`
							);
						}
						return name + `${params[1].marker}${params[1].seriesName}: ${toThousandslsFilter(params[1].data)}`;
					}
				}
				return yes;
			};
			this.ccuLine.series.push(
				{
					name: this.$t('charts.ccuYesterday'),
					type: 'line',
					itemStyle: { color: '#90A4AE' },
					lineStyle: { color: '#90A4AE' },
					symbol: 'none',
					data: yesterday,
				},
				{
					name: this.$t('charts.ccuToday'),
					type: 'line',
					itemStyle: { color: '#EC407A' },
					lineStyle: { color: '#EC407A' },
					symbol: 'none',
					markPoint: {
						label: {
							formatter: (parameter) => toThousandslsFilter(parameter.value),
						},
						symbolSize: [1, 1],
						data: [{ type: 'max', name: 'MAX', label: { position: 'top', color: '#FFF' } }],
					},
					data: today,
				}
			);
		},
		async revenueChart(res, res2) {
			const barData = [];
			const lineData = [];
			const xAxisData = [];
			for (let i = 0; i < 24; i += 1) {
				xAxisData.push(i);
			}
			if (res && res2) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				res2.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					if (this.$moment(res[i].dim_ptt).format('YYYY-MM-DD') === this.$moment(this.date).format('YYYY-MM-DD')) {
						barData.push(res[i].dpst_amt);
						lineData.push(res[i].dpst_amt - res2[i].wdrl_amt);
					}
				}
			}
			this.revenueBar = await revenueChart({
				title: this.$t('charts.overViewDpstH'),
				date: {
					name: this.$t('charts.hour'),
					format: 'HH',
				},
				xAxis: {
					data: xAxisData,
				},
				bar: {
					name: this.$t('charts.dpst'),
					data: barData,
				},
				line: {
					name: this.$t('charts.revenue'),
					data: lineData,
				},
			});
			this.revenueBar.xAxis[0].axisLabel = {
				color: '#FFF',
			};
			this.revenueBar.xAxis[0].data = xAxisData;
			this.revenueBar.tooltip = {
				confine: true,
				axisPointer: {
					type: 'shadow',
				},
				textStyle: { color: 'black' },
				formatter: (params) => {
					let str = `${this.$t('charts.hour')}: ${params[0].name}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				},
				trigger: 'axis',
				triggerOn: 'mousemove',
			};
		},
		async domainChart(res, res2) {
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if ((res, res2)) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].dim_site_id);
						data.push(roundDecimal(res[i].apv_bet));
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].dim_site_id);
						data.push(roundDecimal(res2[i].house_net_win));
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const tmp = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / tmp) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: ${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.domainBar = await bar();
			this.domainBar.color = COLORS.darker_blue;
			this.domainBar.title.text = this.$t('charts.SummaryDomain');
			this.domainBar.grid = { top: '23%', left: '5%' };
			this.domainBar.xAxis.type = 'value';
			this.domainBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.domainBar.yAxis[0].data = yAxisData;
			this.domainBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.domainBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: COLORS.dark_mustard,
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFDC35',
				},
				percent: {
					color: '#FFF',
				},
			};
			this.domainBar.yAxis[0].type = 'category';
			this.domainBar.yAxis[0].axisLabel.inside = true;
			this.domainBar.yAxis[0].axisLine.onZero = false;
			this.domainBar.yAxis[0].zlevel = 1;
			this.domainBar.tooltip.backgroundColor = '#FFF';
			this.domainBar.tooltip.formatter = (params) => `
			<span style='color:#333';>${this.$t('charts.domain')}: ${params[0].name.toString().split(':', 1)}</span><br/><span style='color:#6C6C6C';>${
				tooltipObj[params[0].name.toString().split(':', 1)]
			}</span><br/>`;
			if (yAxisData.length > 10) {
				this.domainBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 85,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.domainBar.series.data = data;
		},
		async brandChart(res, res2) {
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if ((res, res2)) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].dim_brand_code);
						data.push(roundDecimal(res[i].apv_bet));
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].dim_brand_code);
						data.push(roundDecimal(res2[i].house_net_win));
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const tmp = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / tmp) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: ${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.brandBar = await bar();
			this.brandBar.color = '#598e6b';
			this.brandBar.title.text = this.$t('charts.SummaryPlatform');
			this.brandBar.grid = { top: '23%', left: '5%' };
			this.brandBar.xAxis.type = 'value';
			this.brandBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.brandBar.yAxis[0].data = yAxisData;
			this.brandBar.yAxis[0].type = 'category';
			this.brandBar.yAxis[0].axisLine.onZero = false;
			this.brandBar.yAxis[0].axisLabel.inside = true;
			this.brandBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.brandBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: '#ffc964',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #c8e6c9',
				},
				percent: {
					color: '#FFF',
				},
			};
			this.brandBar.yAxis[0].zlevel = 1;
			this.brandBar.tooltip.backgroundColor = '#FFF';
			this.brandBar.tooltip.formatter = (params) =>
				`<span style='color:#333';>${this.$t('charts.platform')}: ${params[0].name
					.toString()
					.split(':', 1)}</span><br/><span style='color:#6C6C6C';>${
					tooltipObj[params[0].name.toString().split(':', 1)]
				}</span><br/>`;
			if (yAxisData.length > 10) {
				this.brandBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 85,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.brandBar.series.data = data;
		},
		async gameTypeChart(res, res2) {
			const tooltipObj = {};
			const data = [];
			const yAxisData = [];
			const percent = [];
			const rank = [];
			if ((res, res2)) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				if (this.data == this.$t('charts.turnover')) {
					for (let i = 0; i < res.length; i += 1) {
						yAxisData.push(res[i].game_kind_name);
						data.push(roundDecimal(res[i].apv_bet));
					}
				} else if (this.data == this.$t('charts.netWin')) {
					for (let i = 0; i < res2.length; i += 1) {
						yAxisData.push(res2[i].game_kind_name);
						data.push(roundDecimal(res2[i].house_net_win));
					}
				}
			}
			const yAxisDataId = yAxisData.map((x) => x);
			const tmp = data.reduce((total, val) => {
				return total + val;
			}, 0);
			for (let i = 0; i < data.length; i += 1) {
				percent.push(roundDecimal((data[i] / tmp) * 100) || [0]);
			}
			for (let k = data.length; k > 0; k--) {
				rank.push(k);
			}
			for (let j = 0; j < data.length; j += 1) {
				yAxisData[j] = `${yAxisData[j]}: \n${nFormatter(data[j], 1)} (${percent[j]}%)`;
				tooltipObj[yAxisDataId[j]] = `Rank : ${rank[j]}`;
			}
			this.gameTypeBar = await bar();
			this.gameTypeBar.color = COLORS.indigo;
			this.gameTypeBar.title.text = this.$t('charts.SummaryGameKind');
			this.gameTypeBar.grid = { top: '23%', left: '5%' };
			this.gameTypeBar.xAxis.type = 'value';
			this.gameTypeBar.xAxis.axisLabel.formatter = (value) => {
				return `${nFormatter(value, 1)}`;
			};
			this.gameTypeBar.yAxis[0].type = 'category';
			this.gameTypeBar.yAxis[0].axisLine.onZero = false;
			this.gameTypeBar.yAxis[0].axisLabel.inside = true;
			this.gameTypeBar.yAxis[0].axisLabel.formatter = (value) => {
				var arr = value.split(' ');
				return `{site|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
			};
			this.gameTypeBar.yAxis[0].axisLabel.rich = {
				site: {
					color: '#FFF',
					fontWeight: 900,
					textShadow: '0.25px 0.25px 0.5px #FFF',
				},
				amt: {
					color: '#ffc964',
					fontWeight: 900,
					textShadow: '0.5px 0.5px 0.5px #BEBEBE',
				},
				percent: {
					color: '#FFF',
				},
			};
			this.gameTypeBar.yAxis[0].zlevel = 1;
			this.gameTypeBar.yAxis[0].data = yAxisData;
			if (yAxisData.length > 10) {
				this.gameTypeBar.dataZoom = [
					{
						show: true,
						yAxisIndex: 0,
						filterMode: 'empty',
						textStyle: { color: 'none' },
						start: 90,
						end: 100,
						type: 'slider',
						showDetail: false,
						showDataShadow: false,
					},
				];
			}
			this.gameTypeBar.tooltip.backgroundColor = '#FFF';
			this.gameTypeBar.tooltip.formatter = (params) =>
				`<span style='color:#333';>${this.$t('charts.gameKind')}: ${params[0].name.toString().split(':', 1)}</span>
					<br/><span style='color:#6C6C6C';>${tooltipObj[params[0].name.toString().split(':', 1)]}</span>`;
			this.gameTypeBar.series.data = data;
		},
		async deviceChart(res) {
			const sum = [], data = [];
			let legendData = [];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					sum.push(res[i].login_count);
					legendData.push(res[i].dim_device_id);
					data.push({
						value: res[i].login_count,
						name: res[i].dim_device_id,
					});
				}
				this.allLoginCount = sum.reduce((total, val) => {
					return total + val;
				}, 0);
			}
			legendData = Array.from(new Set(legendData));
			data.sort((a, b) => (a.value < b.value ? 1 : -1));
			this.devicePie = norPie();
			this.devicePie.color = this.countColor;
			this.devicePie.title.text = this.$t('charts.dviceCount');
			this.devicePie.title.textStyle.fontSize = 20;
			this.devicePie.legend.data = legendData;
			// this.devicePie.grid.top = '15%';
			this.devicePie.graphic.style.text = this.$t('charts.totalDeviceCount') + '\n' + roundOffFilter(this.allLoginCount);
			this.devicePie.tooltip.formatter = (params) => `
				${params.marker}${params.name}: ${roundOffFilter(params.value)} (${roundDecimal(params.percent)}%)`;
			this.devicePie.series[0].data = data;
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		allClean() {
			for(let key_1 in this.line_chart_options){
				this.line_chart_options[key_1] = {};
			}
			this.ccuLine = {};
			this.revenueBar = {};
			this.domainBar = {};
			this.brandBar = {};
			this.gameTypeBar = {};
			this.devicePie = {};
			this.basic.items = [];
			this.netWinByDomain = [];
			this.netWinByBrand = [];
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		updateTodayDate(val) {
			this.date = val;
		},
		async updateData(val) {
			this.data = val;
			if (Object.keys(this.getTodayResult).length !== 0) {
				this.gaming.sort((a, b) => (a.dim_site_id > b.dim_site_id ? 1 : -1));
				this.domainChart(await trans(this.gaming, 'dim_site_id', 'apv_bet'), await trans(this.gaming, 'dim_site_id', 'house_net_win'));
				this.gaming.sort((a, b) => (a.dim_brand_code > b.dim_brand_code ? 1 : -1));
				this.brandChart(await trans(this.gaming, 'dim_brand_code', 'apv_bet'), await trans(this.gaming, 'dim_brand_code', 'house_net_win'));
				this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
				this.gameTypeChart(
					await trans(this.gaming, 'game_kind_name', 'apv_bet'),
					await trans(this.gaming, 'game_kind_name', 'house_net_win')
				);
			} else {
				this.allClean();
				let noMatchData = this.$t('error.noMatchData');
				this.errorDialogStatus(noMatchData);
			}
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	mounted() {
		this.$emit('emitsavePath', this.$route.path);
		this.$emit('emitupdateTodayDate', this.date);
		this.onResize();
	},
	computed: {
		...mapGetters(['getExchangeRate', 'getDialogShow', 'getStatusDialog']),
	},
	created() {
		this.allClean();
	},
};
</script>
